import { render, staticRenderFns } from "./ContentCategoryComponent.vue?vue&type=template&id=46d540cb&scoped=true"
import script from "./ContentCategoryComponent.vue?vue&type=script&lang=js"
export * from "./ContentCategoryComponent.vue?vue&type=script&lang=js"
import style0 from "./ContentCategoryComponent.vue?vue&type=style&index=0&id=46d540cb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d540cb",
  null
  
)

export default component.exports