<template>
<span>

    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_foto" persistent max-width="600px">
                <v-card>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <img v-if="cont_c_imagem" :src="`${base_url}upload/content_category/g/${cont_c_imagem}`" class="foto-ampliada">
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_foto = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Categorias de Conteúdo</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click="atualizarDados">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'contentCategories.add'}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Nova Categoria
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadContentCategories" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.cont_c_imagem`]="{ item }">
                <td width="15%" v-if="item.cont_c_imagem"><img :src="`${base_url}upload/content_category/p/${item.cont_c_imagem}?${uuid}`" class="avatar"></td>
                <td width="15%" v-if="!item.cont_c_imagem"><img :src="`${base_url}upload/logo.png`" class="avatar"></td>
            </template>

            <template v-slot:[`item.lang_flag`]="{ item }">
                <td><img v-if="item.language.lang_flag" :src="`${base_url}upload/language/${item.language.lang_flag}`" class="avatar"></td>
            </template>

            <template v-slot:[`item.cont_c_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.cont_c_status" @change="onChangeEventHandlertStatus(item.cont_c_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editContentCategory(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteContentCategory(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="ampliarFoto(item.cont_c_imagem)" v-on="on">
                            <v-icon dark class="mdi mdi-image-search"></v-icon>
                        </v-btn>
                    </template>
                    <span>Ampliar Imagem</span>
                </v-tooltip>
            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Voce procurou por "{{ search }}" nenhum item localizado.
                </v-alert>
            </template>
        </v-data-table>
    </v-card>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    created() {
        this.$store.dispatch('loadContentCategories');
    },
    computed: {
        loadContentCategories() {

            if (this.$store.state.contentcategories.items.data == null)
                return []

            return this.$store.state.contentcategories.items.data;
        }
    },
    name: "ContentCategoryComponent",
    data() {
        return {
            cont_c_imagem: null,
            dialog_foto: false,
            search: '',
            rowsPerPageItems: [10, 20, 30, 50, {
                "text": "$vuetify.dataIterator.rowsPerPageAll",
                "value": -1
            }],
            headers: [{
                    text: 'Imagem',
                    align: 'left',
                    sortable: false,
                    value: 'cont_c_imagem'
                },
                {
                    text: 'Idioma',
                    align: 'left',
                    sortable: false,
                    value: 'lang_flag'
                },
                {
                    sortable: false,
                    text: 'Seção',
                    value: 'cont_c_nome'
                },
                {
                    sortable: false,
                    text: 'Tipo',
                    value: 'secao.cont_sec_nome'
                },
                {
                    sortable: false,
                    text: 'Descrição',
                    value: 'cont_c_descricao'
                },
                {
                    sortable: false,
                    text: 'Status',
                    value: 'cont_c_status'
                },
                {
                    text: 'Ações',
                    align: 'center',
                    sortable: false,
                    value: 'acoes'
                }
            ],
            contentCategory: [],
            base_url: URL_BASE
        }
    },
    methods: {
        atualizarDados() {
            this.$store.dispatch('loadContentCategories');
        },
        ampliarFoto(foto) {
            this.cont_c_imagem = foto;
            this.dialog_foto = true;
        },
        editContentCategory(item) {
            this.$router.push({
                name: 'contentCategories.edit',
                params: {
                    cont_c_id: item.cont_c_id
                }
            });
        },
        deleteContentCategory(item, index) {
            this.$swal({
                title: 'Você tem certeza?',
                text: "Todos as Categorias e Conteúdos dessa seção serão excluídos",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#D32F2F',
                cancelButtonColor: '#1976D2',
                confirmButtonText: 'Sim, Exclua!'
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch('destroyContentCategory', item.cont_c_id)
                        .then(response => {
                            if (response) {
                                this.$swal({
                                    position: "center",
                                    icon: "success",
                                    title: 'Sucesso',
                                    text: 'Dados excluídos com sucesso',
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.$store.state.contentcategories.items.data.splice(index, 1);
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "error",
                                    title: 'Opssss!',
                                    text: 'Erro ao excluir dados',
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: 'Opssss!',
                                text: 'Algo errado aconteceu!',
                                showConfirmButton: true,
                                timer: 6000
                            });
                        })
                }
            })

        },
        onChangeEventHandlertStatus(cont_c_id, value) {
            this.$store.dispatch('updateContentCategoryStatus', {
                    cont_c_id: cont_c_id,
                    cont_c_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: 'Sucesso',
                        text: 'Status atualizado com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Opssss!',
                        text: 'Erro ao atualizar status',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}

.foto-ampliada {
    max-width: 100%
}
</style>
